export const events = {};

export const getArgumentsLists = (eventName, channel, params = {}, options) => {
  const { appDefId, ...eventParams } = params;

  if (!channel.events) {
    if (options && options.preventDefaultChannels) {
      return [];
    }
    return [[eventName, eventParams, ...(options ? [options] : [])]];
  }

  options = options || {};
  options.config = channel.config;
  options.context = options.context || {};

  if (typeof options.context === 'boolean') {
    options.context = {
      isFirstVisit: options.context,
    };
  }

  options.context.appDefId = options.context.appDefId || appDefId;
  return channel.events[eventName]
    ? channel.events[eventName](eventParams, options)
    : [];
};
