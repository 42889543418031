import { v4 as uuidv4 } from 'uuid';
import { getArgumentsLists } from './events-mapper';

export const dispatchEvent = (channels, eventName, params, options) => {
  const enrichedParams = { ...params, _internalEventId: uuidv4() };
  channels.forEach((channel) => {
    getArgumentsLists(
      eventName,
      channel,
      enrichedParams,
      options,
    ).forEach((args) => channel.report(...args));
  });
};
