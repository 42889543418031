/*
 * Adapter for yandexMetrica
 */

const preventReport = [];

export const channelEvents = {
  PageView: (_, { context } = {}) =>
    context && context.isFirstVisit ? preventReport : [[]],
};
